





































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { EssayItem } from '@/models/miscellaneous';
import Editor from '@tinymce/tinymce-vue';
import Dropdown from '@/components/common/Dropdown.vue';
import { RequireTextDropdownValues } from '@/utils/constants';
import { DropdownItem } from '@/models/dropdown';
@Component({
  components: {
    Editor,
    Dropdown,
  },
})
export default class EssayTemplate extends Vue {
  @Prop({ required: true })
  data!: EssayItem;

  get dropdownData() {
    return RequireTextDropdownValues;
  }

  selectedRequireText = `- Select -`;
  previousSelectedRequireText = ``;

  currentValue: EssayItem = {
    answerText: '',
    requireText: '',
  };

  mounted() {
    this.currentValue.answerText = this.data.answerText;
  }

  // NOTE: do not need to reimport node_modules since there was a call for it in the template
  get EssayTemplateConfig() {
    return {
      height: 350,
      menubar: false,
      external_plugins: {
        tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
      },
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar:
        'undo redo |\
                tiny_mce_wiris_formulaEditor | tiny_mce_wiris_formulaEditorChemistry',
      htmlAllowedTags: ['.*'],
      htmlAllowedAttrs: ['.*'],
      draggable_modal: true,
    };
  }

  private get TinyMceAPIKey() {
    return process.env.VUE_APP_TINY_MCE_API_KEY;
  }

  updateRequireText(dropdownItem: DropdownItem) {
    this.selectedRequireText = dropdownItem.text;
    this.currentValue.requireText = dropdownItem.value;
    this.updateAnswer();
  }

  updateAnswerText(payload: string) {
    this.currentValue.answerText = payload;
    this.updateAnswer();
  }

  @Watch(`data`)
  updateCurrentValue() {
    this.currentValue = this.data;
    this.previousSelectedRequireText = this.currentValue.requireText;
    this.updateAnswer();
  }

  @Emit(`update`)
  updateAnswer() {
    return this.currentValue;
  }
}
