












































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { MultipleChoiceItem } from '@/models/miscellaneous';
import Dropdown from '@/components/common/Dropdown.vue';
import Editor from '@tinymce/tinymce-vue';
import { DropdownItem } from '@/models/dropdown';
import { BasicYesOrNo } from '@/utils/constants';
@Component({
  components: { Dropdown, Editor },
})
export default class MultipleChoiceTemplate extends Vue {
  @Prop({ required: true })
  data!: MultipleChoiceItem;

  @Prop({ required: true })
  showDeleteButton!: boolean;

  @Prop({ default: false })
  isEditMode!: boolean;

  // NOTE: do not need to reimport node_modules since there was a call for it in the template
  get MultipleChoiceTemplateConfig() {
    return {
      height: 350,
      menubar: false,
      external_plugins: {
        tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
      },
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar:
        'undo redo |\
                tiny_mce_wiris_formulaEditor | tiny_mce_wiris_formulaEditorChemistry',
      htmlAllowedTags: ['.*'],
      htmlAllowedAttrs: ['.*'],
      draggable_modal: true,
    };
  }

  dropdownData: DropdownItem[] = BasicYesOrNo;
  selectedAnswer = `- Select -`;

  currentValues: MultipleChoiceItem = {
    id: -1,
    choiceText: ``,
    feedbackText: ``,
    isAnswerValid: false,
  };

  previousSelectedDropdownValue = ``;

  get currentId() {
    return this.isEditMode ? this.currentValues.id + 1 : this.currentValues.id;
  }

  private get TinyMceAPIKey() {
    return process.env.VUE_APP_TINY_MCE_API_KEY;
  }

  mounted() {
    this.currentValues = this.data;
    if (this.isEditMode) {
      this.previousSelectedDropdownValue = this.data.isAnswerValid ? 'yes' : 'no';
    }
  }

  updateDropdownValue(payload: DropdownItem) {
    this.selectedAnswer = payload.text;
    this.currentValues.isAnswerValid = payload.value === 'yes';
    this.updateAnswer();
  }

  updateChoiceText(payload: string) {
    this.currentValues.choiceText = payload;
    this.updateAnswer();
  }

  updateFeedbackText(payload: string) {
    this.currentValues.feedbackText = payload;
    this.updateAnswer();
  }

  @Watch(`data`)
  updateCurrentValue() {
    if (this.data) {
      this.currentValues = this.data;
      this.selectedAnswer = this.currentValues.isAnswerValid ? 'Yes' : 'No';
    }
  }

  @Emit(`update`)
  updateAnswer() {
    return this.currentValues;
  }

  @Emit(`remove`)
  removeCurrentChoice() {
    return this.currentValues;
  }
}
